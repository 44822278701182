import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './App.css';
import SignIn from './components/auth/SignIn';
import SignUp from './components/auth/SignUp';
import CreateEvent from './components/events/CreateEvent'
import NavBar from './components/layout/NavBar'
import Dashboard from './components/dashboard/Dashboard';
import EventDetails from './components/events/EventDetails';
import Profile from './components/auth/Profile'
import Privacy from './components/dashboard/Privacy'
import TOS from './components/dashboard/TOS'
import Contact from './components/dashboard/Contact'
import Thank from './components/dashboard/Thank'

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <NavBar />
          <div className="main">
            <Switch>
              <Route exact path='/' component={Dashboard} />
              <Route exact path='/event/:id' component={EventDetails} />
              <Route exact path='/user/:uid' component={Profile} />
              <Route exact path='/signin' component={SignIn} />
              <Route exact path='/signup' component={SignUp} />
              <Route exact path='/create' component={CreateEvent} />
              <Route exact path='/tos' component={TOS} />
              <Route exact path='/privacy' component={Privacy} />
              <Route exact path='/contact' component={Contact} />
              <Route exact path='/thank' component={Thank} />
            </Switch>
          </div>
          
        </div>
      </BrowserRouter>
    )
  }
}
export default App

