export const sendFeedback = (fb) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        // const profile = getState().firebase.profile;
        // const authorId = getState().firebase.auth.uid;
        // get id before created
        const ref = firestore.collection('feedback').doc();
        const id = ref.id;
        firestore.collection('feedback').doc(id).set(
            {
                ...fb
            }
        ).then(() => {
            dispatch({
                type: 'SEND_FEEDBACK', fb
            })
        }).catch((err) => {
            dispatch({
                type: 'SEND_FEEDBACK_ERROR', err
            })
        })

    }
}
