
import React from 'react'
import { NavLink } from 'react-router-dom'

const SignedOutLinks = () => {
  return (
    <div>
      <ul className="right">
        <li><NavLink to='/create' className="red-text text-darken-1">
          <i className="material-icons left" style={{ lineHeight: "64px" }}>add</i>
          New Event</NavLink></li>
        <li><NavLink to='/signup' className="red-text text-darken-1">Sign Up</NavLink></li>
        <li><NavLink to='/signin' className="red-text text-darken-1">Sign In</NavLink></li>
      </ul>
    </div>
  )
}

export default SignedOutLinks