import React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import Footer from '../layout/Footer'
import Parser from 'html-react-parser/dist/html-react-parser';

const TOS = ({ info }) => {
    if (info) {
        console.log(info.tos)
    }
    return (
        <React.Fragment>
            <div className="container pad-container">
                <h5 style={{ marginBottom: '2rem' }}>
                    Terms of Service</h5>
                {info && Parser(info.tos.text)}
            </div>
            <Footer style={{ position: 'fixed', bottom: 0, left: 0, width: '100%' }} />
        </React.Fragment>
    )
}
const mapStateToProps = (state) => {
    // console.log(state)
    return {
        info: state.firestore.data.info,
        auth: state.firebase.auth
    }
}
export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        {
            collection: 'info',
        }
    ])
)(TOS)
