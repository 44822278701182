import React from 'react'
import Footer from '../layout/Footer'
import "../style.css"

class Thank extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="container pad-container">
                    <h5>Thank you for your feedback. </h5>
                </div>
            </React.Fragment>
        )
    }
}

export default Thank