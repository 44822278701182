import React, { Component } from 'react'
import EventList from '../events/EventList'
import { connect } from "react-redux";
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux'
import Footer from '../layout/Footer'


class Dashboard extends Component {
  render() {
    // console.log("this.props", this.props)
    const { events, notifications } = this.props;
    return (
      <React.Fragment>
        <div className="dashboard">
          <div className="row">
            <div className="col s12">
              <EventList events={events} notifications={notifications} />
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    )
  }
}
const mapStateToProps = (state) => {
  // console.log(state)
  return {
    events: state.firestore.ordered.events,
    notifications: state.firestore.ordered.notifications
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    { collection: 'events', orderBy: ['createdAt', 'desc'] },
    { collection: 'notifications', limit: 3 }
  ])
)(Dashboard)