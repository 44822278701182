
import React from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { signOut } from '../../store/actions/authActions';
import M from 'materialize-css';

class SignedInLinks extends React.Component {
  componentDidMount() {
    var options = {
      constrainWidth: false,
      hover: true
    }
    // console.log(this.Dropdown);
    // M.Dropdown.init(this.Dropdown, options);
    // var instance = M.Dropdown.getInstance(this.Dropdown);
    // console.log(instance)
    // instance.init()
  }
  render() {
    const { profile, auth} = this.props;
    const LinkAuth = `/user/{auth.uid}`
    return (
      <React.Fragment>
        <ul className="right">
          <li><NavLink to='/create' className="red-text text-darken-1">
            <i className="material-icons left" style={{ lineHeight: "64px" }}>add</i>
            New Event</NavLink></li>
          <li><a onClick={this.props.signOut} className="red-text text-darken-1">Log Out</a></li>
          <li ref={Dropdown => { this.Dropdown = Dropdown; }}
          data-target="dropdown1" className="dropdown-trigger">
            <NavLink to={LinkAuth} className="btn btn-floating white lighten-1 red-text">
            {profile.initial}
          </NavLink></li>

          {/* <ul id="dropdown1" style={{ left: "200px" }}
            className='dropdown-content'>
            <li><a>Setting</a></li>
            <li><a>Profile</a></li>
            <li><a className="red-text text-darken-2"
              onClick={(e) => this.props.handleDeleteVideo(e, url)}>Delete</a></li>
          </ul> */}
        </ul>
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = (dispath) => {
  return {
    signOut: () => dispath(signOut())
  }
}
export default connect(null, mapDispatchToProps)(SignedInLinks)