import React, { Component } from "react";
import '../style.css'
import moment from 'moment'
import M from 'materialize-css';
import { validURL } from './parseVideoUrl';


class EventTop extends Component {
    state = {
        showAddVideoBtn: true,
        disableDelete: false,
        showUrlField: false,
        askSignIn: false,
        numNewUrl: 1,
        newUrls: [""]
    }
    componentDidMount() {
        // console.log(this.Tooltip);
        M.Tooltip.init(this.Tooltip);
        M.Tooltip.init(this.Tooltip2);
        // M.Tooltip.getInstance(this.Tooltip);
    }
    showUrlField = () => {
        const { auth } = this.props;
        if (!auth.uid) {
            this.setState({
                askSignIn: true
            })
        } else {
            this.setState({
                showUrlField: true,
                showAddVideoBtn: false,
            })
        }

    }
    handleDeleteEvent = (e, id) => {
        e.preventDefault();
        this.props.deleteEvent(id);
        this.props.history.push('/')
    }

    handleArchiveEvent = (e, id, auth) => {
        e.preventDefault();
        this.props.archiveEvent(id, auth);
        this.props.history.push('/')
    }

    handleSubmit = (e) => {
        e.preventDefault()
        // console.log(this.state.showUrlField)
        const id = this.props.id;
        var newUrls = this.state.newUrls;
        var oldUrls = this.props.event.urls;
        var urls = oldUrls.concat(newUrls)
        console.log(urls)
        this.props.updateUrls(id, urls)

        this.setState({
            showUrlField: false,
            showAddVideoBtn: true,
            numNewUrl: [""]
        })
    }

    handleUrlChange = (e, index) => {
        let newUrls = this.state.newUrls
        newUrls[index] = {
            url: e.target.value,
            ctbtorId: this.props.auth.uid,
            ctbtorUserName: this.props.auth.displayName,
            createdAt: new Date()
        }
        this.setState({
            newUrls: newUrls
        })
    }
    addUrl = (e) => {
        e.preventDefault();
        this.setState({
            newUrls: [...this.state.newUrls, ""],
            numNewUrl: this.state.numNewUrl + 1
        })
    }
    removeUrl = (e) => {
        e.preventDefault();
        var popped = this.state.newUrls;
        popped.pop()
        this.setState({
            newUrls: popped,
            numNewUrl: this.state.numNewUrl - 1
        })
        if (this.state.newUrls.length === 0) {
            this.setState({
                newUrls: [""],
                showUrlField: false,
                showAddVideoBtn: true
            })
        }
    }
    render() {
        const { event, auth, id } = this.props;
        console.log(auth)
        var { newUrls } = this.state;
        var buttonDisabled = true;
        for (let i=0; i<newUrls.length; i++){
            console.log(newUrls[i])
            if (!validURL(newUrls[i].url)){
                buttonDisabled = true;
            }else{
                buttonDisabled = false;
            }
        }
        var dataTooltipDelete = auth.uid === event.authorId ? 'careful!' : 'only creator can delete the event'
        var dataTooltipArchive = auth.uid === undefined ? 'log in to archive' : 'if the event is not live anymore'
        return (
            <div className="event-detail-top" style={{ margin: "2rem auto" }}>
                <div className="row event-title-row" >
                    <div className="col s6">
                        <div className="event-details-title">{event.title}</div>
                        <p className="s-magrin">{event.desc && event.desc}</p>
                        <p className="s-magrin grey-text">Created by {event.authorUserName} {moment(event.createdAt.toDate()).calendar()}</p>
                    </div>
                    <div className="col s6">
                        { auth.uid === 'sRzJroOrD0PJs4pNux33puCPzBe2' &&
                            <div className="tooltipped right" style={{ height: "36px" }}
                                data-position="bottom"
                                ref={Tooltip => { this.Tooltip = Tooltip }}
                                data-tooltip={dataTooltipDelete}>
                                <a  className="event-detail-top-btn delete-btn right red-text accent-2-text 
                                    waves-effect waves-red waves-accent-2 btn-flat"
                                    onClick={(e) => this.handleDeleteEvent(e, id)}
                                    disabled={auth.uid !== event.authorId}>
                                    Delete
                                </a>
                            </div>
                        }
                            
                        <div className="tooltipped right" style={{ height: "36px" }}
                            data-position="bottom"
                            ref={Tooltip => { this.Tooltip2 = Tooltip }}
                            data-tooltip={dataTooltipArchive}>
                            <a className="event-detail-top-btn delete-btn right red-text accent-2-text 
                                waves-effect waves-red waves-accent-2 btn-flat"
                                onClick={(e) => this.handleArchiveEvent(e, id, auth)}
                                disabled={auth.uid == undefined}>
                                Archive
                            </a>
                        </div>
                        {
                            this.state.showAddVideoBtn &&
                            <a className="right red accent-2 waves-effect waves-light btn"
                                onClick={this.showUrlField}
                                style={{ marginLeft: "1rem" }}>
                                {/* <i className="material-icons left">add</i> */}
                                <span>Add Live Video</span>
                            </a>
                        }
                    </div>
                </div>
                {/* ========== showUrlField =========== */}
                {
                    this.state.showUrlField &&
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                        <div className="row event-title-row" id="add-url-row">
                            {
                                this.state.newUrls.map((url, index) =>
                                    <div className="col s10" key={index}>
                                        <div className="input-field" >
                                            <input type="text"
                                                onChange={(e) => this.handleUrlChange(e, index)}></input>
                                            <label htmlFor="url">Live Stream URL</label>
                                        </div>
                                    </div>
                                )}
                            <div className="col s2">
                                <a onClick={this.removeUrl} style={{ cursor: "pointer" }}>
                                    <i className="material-icons event-details-icons">remove_circle_outline</i>
                                </a>
                                <a onClick={this.addUrl} style={{ cursor: "pointer" }}>
                                    <i className="material-icons event-details-icons">add_circle_outline</i>
                                </a>
                            </div>
                        </div>
                        <button className="red accent-2 waves-effect waves-light btn"
                            disabled={buttonDisabled}>
                            <span>Add</span>
                            <i className="material-icons right">send</i>
                        </button>
                    </form>
                }{
                    this.state.askSignIn &&
                    <div>
                        <span>Please sign in to continue</span>
                        <a href="/signin" style={{ marginLeft: "1rem" }}
                            className="red accent-2 waves-effect waves-light btn">
                            Sign In
                        </a>
                    </div>
                }
            </div>

        )
    }

}
export default EventTop