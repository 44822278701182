import React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import Footer from '../layout/Footer'
import Parser from 'html-react-parser/dist/html-react-parser';


const Privacy = ({ info }) => {
    return (
        <React.Fragment>
            <div className="container pad-container">
                <h5 style={{ marginBottom: '2rem' }}>
                    Privacy Policy</h5>
                {info && Parser(info.privacy.text)}
            </div>
            <Footer />
        </React.Fragment>
    )
}
const mapStateToProps = (state) => {
    // console.log(state)
    return {
        info: state.firestore.data.info,
    }
}
export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        {
            collection: 'info',
        }
    ])
)(Privacy)