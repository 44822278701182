import React from 'react'
import moment from 'moment'
import { parseThumbnail } from './parseVideoUrl';
import logo from './EV-logo-thumbnail.svg'

class EventSummary extends React.Component {
  state = {
    thumbUrl: undefined,
    isLive: false
  }
  componentDidMount() {
    var firstUrl = this.props.event.urls[0].url
    parseThumbnail(firstUrl)
      .then(thumbUrl => {
        this.setState({ thumbUrl })
      })
    var createdAt = this.props.event.createdAt.toDate().getTime() / 1000
    var currentTime = new Date().getTime()/1000
    if (currentTime - createdAt < 4*3600){
      this.setState({
        isLive:true
      })
    }
    // console.log()
  }
  render() {
    const { event } = this.props
    var thumbUrl = this.state.thumbUrl;
    // console.log(event.title, thumbUrl)
    return (
      <div className="card event-summary">
        <div className="card-image">
          {this.state.isLive &&
            <div className="ev-mg-05 ev-absolute zindex-99 ev-top-0 ev-left-0">
            <div className="ev-pd-x-05 red accent-2">
              <span className="ev-strong white-text">LIVE</span>
            </div>
          </div>
          }
          
          {/* <div className="ev-mg-05 ev-absolute zindex-99 ev-bottom-0 ev-left-0">
            <div className="ev-pd-x-05 gray">
              <span className="ev-strong white-text">viewers</span>
            </div>
          </div> */}

          {(thumbUrl && thumbUrl !== '') ?
            <img src={thumbUrl} />
            :
            <img className="video-thumbnail" src={logo} />}
            {/* <img className="video-thumbnail" data-src="holder.js/100px300" />} */}

        </div>
        <div className="card-content grey-text text-darken-3">
          <span className="custom-card-title ">{event.title}</span>
          <p className="card-subtitle" style={{fontSize: "0.8rem"}}>Created by {event.authorUserName}</p>
          <p className="grey-text card-subtitle">{moment(event.createdAt.toDate()).calendar()}</p>
        </div>
      </div>
    )
  }
}

export default EventSummary