import React from 'react'
import '../style.css'
import ChatRoom from '../chat/ChatRoom'
import { Redirect } from 'react-router-dom';
import { updateUrls } from '../../store/actions/eventActions'
import { deleteEvent } from '../../store/actions/eventActions'
import { archiveEvent } from '../../store/actions/eventActions'

import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import Player from './Player'
import EventTop from './EventTop'

class EventDetails extends React.Component {

  handleDeleteVideo = (e, urlToDelete) => {
    e.preventDefault();
    const id = this.props.match.params.id;
    const { event } = this.props;
    var oldUrls = event.urls;
    var trimmedUrls = oldUrls.filter(url => url !== urlToDelete);
    console.log(oldUrls, trimmedUrls, urlToDelete)
    this.props.updateUrls(id, trimmedUrls)
  }

  render() {
    const id = this.props.match.params.id;
    const { event, auth } = this.props;
    let props = {
      id: this.props.match.params.id,
      event: event,
      auth: auth,
      updateUrls: this.props.updateUrls,
      deleteEvent: this.props.deleteEvent,
      archiveEvent: this.props.archiveEvent,
      history: this.props.history
    }
    if (event) {
      return (
        <div className="event-detail">
          <div className="row event-row">
            <div className="col s8 event-left">
              <EventTop {...props} />
              {/* ========== Render Videos =========== */}
              {
                event.urls && event.urls.map((url, index) =>
                  <div className="player-wrapper col s12 m12 l12" key={index}>
                    <Player url={url} index={index}
                      handleDeleteVideo={this.handleDeleteVideo.bind(this)} />
                  </div>
                )}
            </div>
            {/* end of s8 */}
            <div className="col s4 chatroom-wrapper">
              <ChatRoom eventId={id} />
            </div>
          </div>
        </div >
      )

    } else {
      return (
        <div className="container center">
          <p>Loading Events...</p>
        </div>
      )
    }
  }
}
const mapStateToProps = (state, ownProps) => {
  // console.log(state.firestore.data)
  const id = ownProps.match.params.id;
  const events = state.firestore.data.events ? state.firestore.data.events : state.firestore.data.archivedEvents
  const event = events ? events[id] : null
  return {
    event: event,
    auth: state.firebase.auth
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    updateUrls: (id, newUrls) => dispatch(updateUrls(id, newUrls)),
    deleteEvent: (id) => dispatch(deleteEvent(id)), 
    archiveEvent: (id, auth) => dispatch(archiveEvent(id, auth))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => [
    {
      collection: 'chatrooms',
      doc: props.match.params.id,
      subcollections: [
        { collection: 'messages' }
      ],
      storeAs: 'messages',
      orderBy: ['createdAt']
    },
    {
      collection: 'chatrooms',
      doc: props.match.params.id,
      // orderBy: ['createdAt']
    },
    {
      collection: 'events',
      doc: props.match.params.id,
      // orderBy: ['createdAt','desc']
    },
    {
      collection: 'archivedEvents',
      doc: props.match.params.id,
    }
  ])
)(EventDetails)
