import React from 'react'
import Footer from '../layout/Footer'
import "../style.css"
import { connect } from 'react-redux';
import { sendFeedback } from '../../store/actions/feedbackActions'

class Contact extends React.Component {
    state = {
        subject: '',
        userName: this.props.auth.displayName,
        userId: this.props.auth.uid,
        email: this.props.auth.email,
        msg: ''
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.sendFeedback(this.state)
        this.props.history.push('/thank')
    }
    render() {
        const { auth } = this.props;
        var { subject, name, email, msg } = this.state;
        var buttonDisabled = true;
        if (subject !== '' & name !== '' & email !== '' & msg !== '') {
            buttonDisabled = false
        }
        return (
            <React.Fragment>
                <div className="container pad-container">
                    <form className="white" onSubmit={this.handleSubmit}>
                        <h5 className="grey-text text-darken-3">Contact</h5>
                        <div className="row">
                            <div className="input-field col s12">
                                <input type="text" id='subject' onChange={this.handleChange} />
                                <label htmlFor="subject">Subject</label>
                            </div>
                            <div className="contact-col col s6">
                                <div className="input-field">
                                    <input type="text" id='name' defaultValue={auth.displayName}
                                        onChange={this.handleChange} />
                                    <label className="active" htmlFor="name">Name</label>
                                </div>
                            </div>
                            <div className="contact-col col s6">
                                <div className="input-field">
                                    <input type="text" id='email' defaultValue={auth.email}
                                        onChange={this.handleChange} />
                                    <label className="active" htmlFor="email">Email</label>
                                </div>
                            </div>

                            <div className="input-field col s12">
                                <textarea className="materialize-textarea" type="text" id='msg'
                                    onChange={this.handleChange} />
                                <label htmlFor="msg">Messsage</label>
                            </div>

                            <div className="input-field col 12">
                                <button className="btn red accent-2 lighten-1 waves-effect waves-light"
                                    disabled={buttonDisabled} type="submit" >
                                    Submit
                        </button>
                            </div>
                        </div>
                    </form>
                </div>
                <Footer style={{position: "fixed",bottom:0}}/>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    // console.log(state)
    return {
        auth: state.firebase.auth
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        sendFeedback: (fb)=> dispatch(sendFeedback(fb))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Contact)

