import React from 'react'

class Notification extends React.Component {
    render() {
        const { notifications } = this.props
        // console.log(notifications)
        return (
            <div className="card">
                <div className="card-content grey-text text-darken-3">
                    {/* <span className="custom-card-title ">Notification</span> */}
                    <ul style={{margin: 0}}>
                        {
                            notifications && notifications.map(item => {
                                return <li key={item.id}>
                                    {item.user}{item.content}
                                </li>
                            })
                        }
                    </ul>

                    {/* <p className="grey-text">XX Created a New Event!</p> */}
                </div>
            </div>
        )
    }
}
export default Notification