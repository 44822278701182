import React from 'react'
import EventSummary from "./EventSummary";
import { Link } from 'react-router-dom'
import Notification from './Notification'

class EventList extends React.Component {
  render() {
    const { events, notifications } = this.props;
    // console.log(notifications)
    return (
      <div className="event-list section">
        {/* <div className="row" style={{marginBottom: 0}}>
          <Notification notifications={notifications} />
        </div> */}
        <h5>Available Live Events</h5>
        <div className="row">
          {events && events.map(event => {
            return (
              <div className="col s12 m3" key={event.id}>
                <Link to={'/event/' + event.id} >
                  <EventSummary event={event} />
                </Link>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default EventList