import React, { Component } from 'react'
import "../style.css"
import { connect } from 'react-redux';
import { createEvent } from '../../store/actions/eventActions'
import { Redirect } from 'react-router-dom';
import firebase from '../../config/baseConfig';
import { validURL } from './parseVideoUrl';
import M from 'materialize-css';
// AIzaSyBXBbRHywzfvhjhIPXdlY9_siVUUUsvFgM
/* global google */

class CreateEvent extends Component {
  state = {
    numUrl: 1,
    title: '',
    desc: '',
    urls: [''],
    category: '',
    location: ''
  }
  constructor(props) {
    super(props);
    this.autocompleteInput = React.createRef();
    this.autocomplete = null;
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
  }
  componentDidMount() {
    M.FormSelect.init(this.FormSelect);
    this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current,
      { "types": ["geocode"] });
    this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
  }
  handlePlaceChanged() {
    const place = this.autocomplete.getPlace();
    const location = {
      address_components: place.address_components,
      place_id: place.place_id,
      reference: place.reference,
      types: place.types,
      url: place.url,
      vicinity: place.vicinity
    }
    this.setState({
      location: location
    })
  }

  handleUrlChange = (e, index) => {
    // console.log(index)
    this.state.urls[index] = {
      url: e.target.value,
      ctbtorId: this.props.auth.uid,
      ctbtorUserName: this.props.auth.displayName,
      createdAt: new Date()
    }
    this.setState({
      urls: this.state.urls
    })
  }
  addUrl = (e) => {
    e.preventDefault();
    this.setState({
      numUrl: this.state.numUrl + 1,
      urls: [...this.state.urls, ""]
    })
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }
  handleSubmit = (e) => {
    e.preventDefault();
    // console.log(this.state);

    this.props.createEvent(this.state)
    this.props.history.push('/')
  }
  render() {
    console.log(this.state)
    var { title, desc, urls } = this.state;
    var urls = urls.filter(function (el) {
      return el != null;
    });
    var buttonDisabled = true;
    if (title !== '' & desc !== '' & typeof (urls[urls.length - 1]) == "object") {
      for (let i = 0; i < urls.length; i++) {
        // console.log(urls[i])
        if (!validURL(urls[i].url)) {
          buttonDisabled = true;
        } else {
          buttonDisabled = false;
        }
      }
    }
    const { auth } = this.props;
    if (!auth.uid) return <Redirect to='/signin' />
    return (
      <div className="container pad-container">
        <form className="white" onSubmit={this.handleSubmit}>
          <h5 className="grey-text text-darken-3">New Event</h5>
          <div className="input-field">
            <input type="text" id='title' onChange={this.handleChange} />
            <label htmlFor="title">Event Title</label>
          </div>
          <div className="input-field">
            <select ref={FormSelect => { this.FormSelect = FormSelect; }}
              defaultValue="0" value={this.category} id="category"
              onChange={this.handleChange}>
              <option value="0" disabled>Choose Event Category</option>
              <option value="gaming">Gaming</option>
              <option value="sports">Sports</option>
              <option value="entmt">Entertainment</option>
              <option value="politics">Politics</option>
              <option value="irl">In Real Life</option>
              <option value="edu">Education</option>
              <option value="tech">Technology</option>
              <option value="other">Other</option>Î
            </select>
            {/* <label>Category</label> */}
          </div>
          <div className="input-field">
            <input ref={this.autocompleteInput} id="autocomplete"
              placeholder="" type="text" />
            {/* <input type="text" id='location' onChange={this.handleChange} /> */}
            <label htmlFor="location">Location</label>
          </div>
          <div className="input-field">
            <input type="text" id='desc' onChange={this.handleChange} />
            <label htmlFor="desc">Description</label>
          </div>

          {
            this.state.urls && this.state.urls.map((url, index) =>
              <div className="input-field" key={index}>
                <input type="text" id="content"
                  data-error="Please type valid URL"
                  onChange={(e) => this.handleUrlChange(e, index)}></input>
                <label htmlFor="url">Live Stream URL</label>
              </div>
            )}
          <div>
            <a className="btn-floating waves-effect waves-light material-icons add_circle_icon red accent-2"
              onClick={this.addUrl}>
              <i className="material-icons">add</i></a>
          </div>
          <div className="input-field">
            <button className="btn red accent-2 lighten-1 waves-effect waves-light"
              disabled={buttonDisabled}>
              Start to Watch!</button>
          </div>
        </form>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  // console.log(state)
  return {
    auth: state.firebase.auth
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    createEvent: (event) => dispatch(createEvent(event)),
    id: (event) => createEvent(event)
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateEvent)
