const initState = {
    events: [
        { id: '1', title: 'lofi hip hop radio', url: 'https://www.youtube.com/watch?v=yaKeFoNOneg' },
        { id: '2', title: 'calm beats', url: 'https://www.youtube.com/watch?v=IXwthT9-xwk' },
        { id: '3', title: '24/7 lofi', url: 'https://www.youtube.com/watch?v=B8tQ8RUbTW8' },
    ]
}
const eventReducer = (state = initState, action) => {

    switch (action.type) {
        // create event
        case 'CREATE_EVENT':
            console.log('created event: ', action.event);
            return state;
        case 'CREATE_EVENT_ERROR':
            console.log('create event error: ', action.err);
            return state
        // create chat room
        case 'CREATE_CHATROOM':
            console.log('chatroom created')
            return state
        case 'CREATE_CHATROOM_ERROR':
            console.log('chatroom created err', action.err)
            return state
        // update videos 
        case 'UPDATE_URLS':
            console.log('update urls: ', action.urls)
            return state
        case 'UPDATE_URLS_ERROR':
            console.log('update urls err', action.err)
            return state
        // dispatch message
        case 'SEND_MESSAGE':
            console.log('sent message: ', action.message);
            return state
        case 'SEND_MESSAGE_ERROR':
            console.log('send message err: ', action.err);
            return state

        case 'DELETE_EVENT':
            console.log('event deleted: ', action.id)
            return state
        case 'ARCHIVE_EVENT':
            console.log('event archived', action.event)
            return state
        case 'ARCHIVE_EVENT_ERROR':
            console.log('event archived error', action.err)
            return state
        default:
            return state
    }
}
export default eventReducer