import urlParser from "js-video-url-parser";

export const parseThumbnail = async (url) => {
    var videoInfo = urlParser.parse(url)
    // console.log('videoInfo: ', videoInfo)
    var thumbUrl = undefined;
    // https://www.youtube.com/watch?v=drfgDDkR-fo
    // https://static-cdn.jtvnw.net/previews-ttv/live_user_riotgames-200x200.jpg
    if (videoInfo !== undefined) {
        if (videoInfo.mediaType === 'video') {
            if (videoInfo.provider === 'youtube') {
                if (videoInfo.id) {
                    var vId = videoInfo.id;
                    // thumbUrl = `https://img.youtube.com/vi/${vId}/maxresdefault.jpg`
                    thumbUrl = `https://img.youtube.com/vi/${vId}/mqdefault.jpg`
                } else {
                    console.log('Error: No id provided')
                }

            } else if (videoInfo.provider === 'twitch') {
                if (videoInfo.id) {
                    return await fetchTwitchThumb(videoInfo.id)
                        .then(url => {
                            // thumbUrl = url
                            console.log('thumbUrl from parse: ', url)
                            return url
                        }).catch(e => {
                            console.log('error when returning twitch video url: ', e)
                            return ''
                        })
                } else {
                    console.log('Error: No id provided')
                }
            }
        } else if (videoInfo.mediaType === 'stream') {
            if (videoInfo.provider === 'twitch') {
                if (videoInfo.channel) {
                    thumbUrl = `https://static-cdn.jtvnw.net/previews-ttv/live_user_${videoInfo.channel}-300x200.jpg`
                    // console.log('parse: ', videoInfo.channel, thumbUrl)
                } else {
                    console.log('Error: No id provided')
                }
            } else if (videoInfo.provider === 'youtube') {
                if (videoInfo.id) {
                    var vId = videoInfo.id;
                    thumbUrl = `https://img.youtube.com/vi/{vId}/0.jpg`
                } else {
                    console.log('Error: No id provided')
                }
            }
        }
    }
    // else if (url.includes('facebook')) {
    //     var videoID = fbvideoID(url)
    //     thumbUrl = 'https://graph.facebook.com/' + videoID + '/picture'
    //     console.log('fb thumbnail: ', thumbUrl)
    // }
    // console.log('url before return: ', thumbUrl)
    // console.log(thumbUrl)
    return thumbUrl
}

const fbvideoID = (url) => {
    var myRegexp = /videos\/(\d+)+|v=(\d+)|vb.\d+\/(\d+)/
    var match = myRegexp.exec(url);
    console.log(match)
    return match[1];
}
const fetchTwitchThumb = async (vId) => {
    const videoAPI = `https://api.twitch.tv/kraken/videos/` + vId;
    // const channelAPI = 'https://api.twitch.tv/kraken/channels/' + channelId;
    // console.log(videoAPI);
    try {
        return await fetch(videoAPI, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/vnd.twitchtv.v5+json',
                'Client-ID': 'maknwwk9b70rf9f6sm7xslhkgxz0wx'
            })
        })
            .then(response => response.json())
            .then(data => {
                // console.log('data: ',data)
                var thumbNails = data.thumbnails
                var url = thumbNails['large'][0]['url']
                // console.log(typeof (url), url)
                return url
            })
            .catch(e => {
                console.log('error in fetch: ', e)
                return 'error'
            })
    } catch (e) {
        console.log('error in fetch 2: ', e)
        return 'error'
    }
}
export const validURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}

// parse YT video
// var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/
// var match = url.match(regExp);
// var vId = (match && match[7].length == 11) ? match[7] : false;
const parseTwitchChannel = (url) => {
    return url.substring(22)
}
const parseTwitchVideo = (url) => {
    return url.substring(29)
}