import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { signUp } from '../../store/actions/authActions'
import "../style.css"
import M from 'materialize-css';
import $ from 'jquery'

/* global google */

class SignUp extends Component {
  state = {
    email: '',
    password: '',
    userName: '',
    checkBox: false,
    checkBoxError: false,
    gender: '',
    location: '',
    interest: []
  }
  constructor(props) {
    super(props);
    this.autocompleteInput = React.createRef();
    this.autocomplete = null;
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
  }
  componentDidMount() {
    M.FormSelect.init(this.FormSelect);
    M.FormSelect.init(this.FormSelect2);
    console.log(this.FormSelect2.value)

    // $(this.refs.FormSelect2).material_select(this.handleInterest.bind(this));

    this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current,
      { "types": ["geocode"] });
    this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
  }
  handlePlaceChanged() {
    const place = this.autocomplete.getPlace();
    const location = {
      address_components: place.address_components,
      place_id: place.place_id,
      reference: place.reference,
      types: place.types,
      url: place.url,
      vicinity: place.vicinity
    }
    this.setState({
      location: location
    })
  }
  handleCheck = (e) => {
    console.log('clicked me')
    this.setState({
      checkBox: !this.state.checkBox
    })
  }
  handleChange = (e) => {
    // console.log(e.target.value)
    this.setState({
      [e.target.id]: e.target.value
    })
  }
  handleInterest = (e) => {
    // console.log(e.target.value)
    var interest = this.state.interest
    var value = e.target.value
    if (!interest.includes(value)) {
      this.setState({
        interest: [...this.state.interest, value]
      })
    }
  }
  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.state.checkBox) {
      this.setState({
        checkBoxError: true
      })
    } else {
      this.props.signUp(this.state)
    }

  }
  render() {
    const { auth } = this.props;
    console.log(this.state)
    if (auth.uid) return <Redirect to='/' />

    const { authError } = this.props;
    return (
      <div className="container pad-container">
        <form className="white" onSubmit={this.handleSubmit}>
          <h5 className="grey-text text-darken-3">Sign Up</h5>

          <div className="row">
            <div className="col s12 m5 signup-input-column">
              <div className="input-field ">
                <label htmlFor="userName">User Name *</label>
                <input type="text" id="userName" onChange={this.handleChange} />
              </div>
              <div className="input-field ">
                <label htmlFor="email">Email *</label>
                <input type="email" id='email' className="validate" onChange={this.handleChange} />
              </div>
              <div className="input-field ">
                <label htmlFor="password">Password * </label>
                <input type="password" id='password' onChange={this.handleChange} />
              </div>
            </div>
            <div className="col s12 m5">
              <div className="input-field">
                <select ref={FormSelect => { this.FormSelect = FormSelect }}
                  defaultValue="0" id="gender"
                  onChange={this.handleChange}>
                  <option value="0" disabled>Gender</option>
                  <option value="m">Male</option>
                  <option value="f">Female</option>
                  <option value="none">I prefer not to disclose</option>
                </select>
              </div>

              <div className="input-field">
                <input ref={this.autocompleteInput} id="autocomplete"
                  placeholder="" type="text" />
                <label htmlFor="location">Location</label>
              </div>

              <div className="input-field">
                <select ref={FormSelect2 => { this.FormSelect2 = FormSelect2 }}
                  defaultValue="0"
                  id="interest" tabIndex='20' 
                  onChange={this.handleChange}>
                  <option value="0"  disabled>Interest</option>
                  <option value="gaming">Gaming</option>
                  <option value="sports">Sports</option>
                  <option value="entmt">Entertainment</option>
                  <option value="politics">Politics</option>
                  <option value="irl">In Real Life</option>
                  <option value="edu">Education</option>
                  <option value="tech">Technology</option>
                  <option value="other">Other</option>
                </select>
                {/* <label>Interest</label> */}
              </div>
            </div>

          </div>
          <div >
            <label >
              <input type="checkbox" className="filled-in"
                onChange={this.handleCheck}
                checked={this.state.checkBox} />
              <span>I agree with EventViewer&nbsp;
                <span>
                  <a href="/tos">Terms of Use</a> and&nbsp;
                  <a href="/privacy">rivacy Policy</a>
                </span>
              </span>
            </label>

          </div>

          <div className="input-field">
            <button className="btn red accent-2 lighten-1 z-depth-0">Sign Up</button>
            <div className="red-text accent-2-text">
              {authError ? <p>{authError}</p> : null}
            </div>
            <div className="red-text accent-2-text">
              {this.state.checkBoxError ?
                <p>Please indicate that you have read and agree to the terms of use and privacy policy. </p>
                : null}
            </div>
          </div>
        </form>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (newUser) => dispatch(signUp(newUser))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)