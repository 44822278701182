const initState = {
    feedback :[
        {subject:'a subject',msg:'a message',userName:'aaa',userId:12345,createdAt:'a time', email:'sample@example.com'}
    ]
}

const feedbackReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SEND_FEEDBACK':
            console.log('send feedback successfully')
            return state
        case 'SEND_FEEDBACK_ERROR':
            console.log('send feedback error')
            return state
        default:
            return state
    }
}

export default feedbackReducer