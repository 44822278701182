import React from 'react';
import { Link } from 'react-router-dom'
import '../style.css';
import SignedInLinks from './SignedInLinks'
import SignedOutLinks from './SignedOutLinks'
import {connect} from 'react-redux';

const NavBar = (props) => {
    const { auth, profile } = props;
    // console.log(props)
    const links = auth.uid ? 
    <SignedInLinks profile={profile} auth={auth}/> 
    : <SignedOutLinks />
    return (
        <nav className="nav-wrapper white">
            <Link to='/' className="brand-logo left navbar red-text text-darken-1" style={{fontSize: "1.5rem"}}>
                Event Viewer - Watch Live Events Together Across Platforms
                </Link>
            { links }
        </nav>
    )
}

const mapStateToProps = (state) => {
    // console.log(state)
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}
export default connect(mapStateToProps)(NavBar);