import React, { Component } from "react";
import ReactPlayer from 'react-player'
import TwitchEmbedVideo from "react-twitch-embed-video"
import '../style.css';
import moment from 'moment'
import M from 'materialize-css';

class Player extends Component {
    componentDidMount() {
        var options = {
            constrainWidth: false,
            // hover: true
        }
        // console.log(this.Dropdown);
        M.Dropdown.init(this.Dropdown, options);
        var instance = M.Dropdown.getInstance(this.Dropdown);
        
    }
    parseTwitchChannel = (url) => {
        return url.substring(22)
    }
    parseTwitchVideo = (url) => {
        return url.substring(29)
    }
    videoSource = (url, index) => {
        var url = url.url
        var videoSource = ''
        if (url.includes('twitch')) {
            if (url.includes('video')) {
                videoSource = 'twitchVideo'
            }
            else {
                videoSource = 'twitchChannel'
            }
        } else if (url.includes('youtube')) {
            videoSource = 'youtube'
        }
        switch (videoSource) {
            case 'twitchChannel':
                return <TwitchEmbedVideo
                    height="100%" width="100%"
                    channel={this.parseTwitchChannel(url)} layout="video"
                    targetClass={`twitch-` + index.toString()}
                />
            case 'twitchVideo':
                return <TwitchEmbedVideo
                    height="100%" width="100%"
                    video={this.parseTwitchVideo(url)}
                    targetClass={`twitch-` + index.toString()}
                />
            case 'youtube':
                return <ReactPlayer
                    className='react-player' width='100%' height='100%' url={url} />
            default:
                return <ReactPlayer
                    className='react-player' width='100%' height='100%' url={url} />
        }
    }
    render() {
        const { index, url } = this.props
        // url is an object
        var ulId = 'dropdown-' + index.toString()
        return (
            <div className="player-div" style={{ height: "100%" }}>
                {/* <p>{url}</p> */}
                <a ref={Dropdown => { this.Dropdown = Dropdown; }}
                    className="dropdown-trigger btn icon-more right white"
                    data-target={ulId}>
                    <i className="material-icons">more_vert</i>
                </a>
                <ul id={ulId} style={{ left: "200px" }}
                    className='dropdown-content'>
                    <li><a className="grey-text text-darken-3">
                        Added by {url.ctbtorUserName}
                        {moment(url.createdAt.toDate()).calendar()}
                    </a></li>
                    <li className="divider" tabIndex="-1"></li>
                    <li><a className="red-text text-darken-2"
                        onClick={(e) => this.props.handleDeleteVideo(e, url)}>Delete</a></li>

                </ul>
                {
                    this.videoSource(url, index)
                }
            </div>
        );
    }


}
export default Player;