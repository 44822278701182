import React, { Component } from 'react'
import '../style.css'
import TextBox from './TextBox'
import { connect } from 'react-redux';
import { sendMessage } from '../../store/actions/chatActions'
import SignIn from '../auth/SignIn';
import MessageList from './MessageList';

class ChatRoom extends Component {
    constructor(props) {
        super(props);
        this.textBox = React.createRef();
    }
    state = {
        showSignIn: false,

    }
    askSignIn = () => {
        this.setState({
            showSignIn: true
        })
    }
    handleSendMessage = (e, msg) => {
        e.preventDefault();
        this.textBox.current.cleanBox();
        const auth = this.props.auth;
        // console.log(auth);
        if (!auth.uid) {
            this.askSignIn()
            console.log('Please sign in! ')
        } else {
            var message = {
                eId: this.props.eventId,
                body: msg.body,
                userId: auth.uid,
                userName: auth.displayName
            }
            this.props.sendMessage(message)
            console.log('submitted!')
        }
    }
    render() {
        // console.log('this.props:', this.props)
        const { event, auth, chatroom, messages } = this.props;
        var messages_arr = []
        if (messages) {
            messages_arr = Object.values(messages)
        }
        // console.log(auth.displayName)
        // console.log(moment(messages_arr[0].createdAt))
        return (
            <div className="chatroom card darken-1">
                <div className="chatroom-topbar">
                    <div className="card-title">
                        Let's Chat!
                    </div>
                </div>
                {
                    this.state.showSignIn ?
                        <div style={{ textAlign: "center" }} >
                            <p>Sign In to Chat!</p>
                            <div style={{ marginTop: "-6rem" }} >
                                <SignIn />
                            </div>

                            {/* <a href="/signin"
                            className="red accent-2 waves-effect waves-light btn">
                            Sign In
                        </a> */}
                        </div>
                        :
                        <MessageList messages_arr={messages_arr} auth={auth} />
                }
                <div className="display-message">

                </div>
                <div className="text-editor">
                    <TextBox auth={auth} askSignIn={this.askSignIn}
                        eventId={this.props.eventId}
                        ref={this.textBox}
                        handleSendMessage={this.handleSendMessage}
                    />
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state, ownProps) => {
    // console.log('log chatroom state: ', state)
    // console.log('log chatroom ownProps: ', ownProps)
    const id = ownProps.eventId;
    const events = state.firestore.data.events
    const event = events ? events[id] : null
    const chatrooms = state.firestore.data.chatrooms
    const chatroom = chatrooms ? chatrooms[id] : null
    const messages = state.firestore.data.messages
    return {
        event: event,
        chatroom: chatroom,
        messages: messages,
        auth: state.firebase.auth
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        sendMessage: (message) => dispatch(sendMessage(message))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoom)