export const createEvent = (event) => {
    // getFirebase does not know about our project config yet
    // they are connected in baseConfig.js
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        // make async call to database 
        // then dispatch to redux center
        const firestore = getFirestore();
        const profile = getState().firebase.profile;
        const authorId = getState().firebase.auth.uid;
        // get id before created
        const ref = firestore.collection('events').doc();
        const id = ref.id;
        // console.log('id: ', id)

        // create an event doc and chatroom doc at the same time 
        firestore.collection('events').doc(id).set(
            {
                urls: event.urls,
                title: event.title,
                desc: event.desc,
                category: event.category,
                location: Object.assign({}, event.location),
                // location: event.location,
                authorUserName: profile.userName,
                authorId: authorId,
                createdAt: new Date()
            }
        ).then(() => {
            dispatch({
                type: 'CREATE_EVENT', event
            })
        }).catch((err) => {
            dispatch({
                type: 'CREATE_EVENT_ERROR', err
            })
        })

        // for now each event has one chat room. 
        // can create n chatrooms in a for loop 
        firestore.collection('chatrooms').doc(id).set({
            eId: id,
            authorUserName: profile.userName,
            authorId: authorId,
            createdAt: new Date()
        }).then(() => {
            dispatch({
                type: 'CREATE_CHATROOM', event
            })
        }).catch((err) => {
            dispatch({
                type: 'CREATE_CHATROOM_ERROR', err
            })
        })
        return id
    }
}

export const updateUrls = (id, urls) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection('events').doc(id).set(
            { urls: urls },
            { merge: true }
        ).then(() => {
            dispatch({
                type: 'UPDATE_URLS', urls
            })
        }).catch((err) => {
            dispatch({
                type: 'UPDATE_URLS_ERROR', err
            })
        })
    }
}
export const deleteEvent = (id) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection('events').doc(id).delete()
            .then(() => {
                dispatch({
                    type: 'DELETE_EVENT'
                })
            }).catch((err) => {
                dispatch({
                    type: 'DELETE_EVENT_ERROR', err
                })
            })
    }
}

export const archiveEvent = (id, auth) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        const eventRef = firestore.collection('events').doc(id);
        eventRef.get().then(function (doc) {
            if (doc.exists) {
                const event = doc.data()
                firestore.collection('archivedEvents').doc(id).set({
                    ...event,
                    archivedAuthorUserName: auth.displayName,
                    archivedAuthorId: auth.uid,
                    archivedAt: new Date()
                }).then(() => {
                    dispatch({
                        type: 'ARCHIVE_EVENT', event
                    })
                }).catch((err) => {
                    dispatch({
                        type: 'ARCHIVE_EVENT_ERROR', err
                    })
                })
                // delete event 
                firestore.collection('events').doc(id).delete()
                    .then(() => {
                        dispatch({
                            type: 'DELETE_EVENT'
                        })
                    }).catch((err) => {
                        dispatch({
                            type: 'DELETE_EVENT_ERROR', err
                        })
                    })
            } else {
                console.log("No such document!");
            }
        }).catch(function (error) {
            console.log("Error getting document:", error);
        });


    }
}
