import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const config = {
    apiKey: "AIzaSyB-yFNKRW5zDd4gfohh5C2hj41e6X5Rwcg",
    authDomain: "eventviewer-2019.firebaseapp.com",
    databaseURL: "https://eventviewer-2019.firebaseio.com",
    projectId: "eventviewer-2019",
    storageBucket: "",
    messagingSenderId: "479337677800",
    appId: "1:479337677800:web:b5863ae81dd6484fdd9a5d",
    measurementId: "G-CSRW4GE3X3"
  };
  firebase.initializeApp(config)
  firebase.firestore().settings({
      timestampsInSnapshots: true
  })

  export default firebase;