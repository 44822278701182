import React from 'react'
import { NavLink } from 'react-router-dom'
import '../style.css'

class Footer extends React.Component {
    render() {
        return (
            <footer className="page-footer white">
                <div className="footer-copyright grey-text text-darken-3" >
                    <ul className="right">
                        <li>© 2020 EventViewer</li>
                        <li>
                            <a className="grey-text text-darken-3" href="/tos">Terms of Service</a>
                        </li>
                        <li>
                            <a className="grey-text text-darken-3" href="/privacy">Privacy Policy</a>
                        </li>
                        <li>
                            <a className="grey-text text-darken-3" href="/contact">Contact</a>
                        </li>
                    </ul>
                </div>
            </footer>
        )
    }
}
export default Footer