const initState = {
    authError: null
}
const authReducer = (state = initState, action) => {
    switch(action.type){
        case 'SIGNIN_ERROR':
            console.log('login error')
            return {
                ...state, 
                authError: 'Login Failed'
            }
        case 'SIGNIN_SUCCESS':
            console.log('login success')
            return {
                ...state,
                authError: null
            }
        case 'SIGNOUT_SUCCESS':
            console.log('signout success');
            return {
                state
            }
        case 'SIGNUP_SUCESS':
            console.log('signup success')
            return {
                ...state,
                authError: null
            }
        case 'SIGNUP_ERROR':
            console.log('signup error: ', action.err.message)
            return {
                ...state,
                authError: action.err.message
            }
        default: 
            return state;
    }
    // then need to call this action from the component 
}
export default authReducer