import React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import moment from 'moment';

class Profile extends React.Component {
    handleChange = () => {

    }
    parseGender = (value) => {
        if (value == 1)
            return 'Male'
        if (value == 2)
            return 'Female'
        if (value == 3)
            return 'Prefer not to disclose'
    }
    render() {
        const { auth, profile } = this.props;
        // console.log(profile.location)
        return (
            <div className="container pad-container">
                <h5 className="grey-text text-darken-3">Profile</h5>

                <div className="row">
                    <div className="col s2">
                        <p>User Name</p>
                        <p>Joined At</p>
                        <p>Email</p>
                        <p>Gender</p>
                        <p>Location</p>
                    </div>
                    <div className="col s10">
                        <p>{auth.displayName}</p>
                        <p>
                            {moment.unix(auth.createdAt / 1000).format("dddd, MMMM Do YYYY, h:mm:ss a")}
                        </p>
                        <p>{profile.email}</p>
                        <p>{this.parseGender(profile.gender)}</p>
                        {profile.location &&
                            <p>{profile.location.address_components[0].long_name}</p>}
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}
export default connect(mapStateToProps)(Profile)

