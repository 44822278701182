import authReducer from './authReducer';
import eventReducer from './eventReducer';
import feedbackReducer from './feedbackReducer';
import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore'
import { firebaseReducer} from 'react-redux-firebase'; // imported on user auth

// firestoreReducer is to sync collections
// firebaseReducer is to sync user auth 
const rootReducer = combineReducers({
    auth: authReducer,
    rootEvent: eventReducer,
    feedback: feedbackReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer
})

export default rootReducer